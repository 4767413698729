import { OutComeType, PracticeElementType, TargetAreaType } from 'src/redux/sessionLogsRedux/types';

export const CONSTANT_SUBMISSION_SL = {
  service: 'service',
  unsched: 'unsched',
  sbbhtiered: 'sbbhtiered',
};

export const getContactTypeName = (contactType: string) => {
  switch (contactType) {
    case 'CONSUL':
      return 'Consultation';
    case 'CRISIS':
      return 'Crisis';
    case 'GROUP':
      return 'Group Session';
    case 'IN-CLASS':
      return 'In-class Support';
    case 'INDIV':
      return 'Individual Session';
    case 'OBS':
      return 'Observation';
    case 'PARENT':
      return 'Parent Training';
    case 'WALKIN':
      return 'Walk-in';
    default:
      return '';
  }
};

export const getReferredByName = (referredBy: string) => {
  switch (referredBy) {
    case 'SELF':
      return 'Self (Student)';
    case 'TEACHER':
      return 'Teacher';
    case 'ADMIN':
      return 'Admin';
    case 'PARENT':
      return 'Parent';
    case 'OTHER':
      return 'Other';
    default:
      return '';
  }
};

export const getTargetName = (targets: TargetAreaType[], targetId: number) => {
  const selected = targets?.find((target, index) => target.targetId === targetId);
  return selected ? selected.targetFullName : `Target ID: ${targetId}`;
};

export const getPracticeElementName = (practiceElements: PracticeElementType[], practiceId: number) => {
  const selected = practiceElements?.find((practice, index) => practice.practiceId === practiceId);
  return selected ? selected.practiceFullName : `Practice ID: ${practiceId}`;
};

export const getOutcomeName = (outcomes: OutComeType[], outcomeId: number) => {
  const selected = outcomes?.find((outcome, index) => outcome.outcomeid === outcomeId);
  return selected ? selected.outcome : `Outcome ID: ${outcomeId}`;
};

export const getAbsentReasonName = (absentReason: string) => {
  switch (absentReason) {
    case 'ABS':
      return 'Absent';
    case 'EVT':
      return 'School Event';
    case 'PKT':
      return 'Paper Packet Provided';
    case 'OTHER':
      return 'Other';
    default:
      return '';
  }
};
