import { ApiResponse } from 'apisauce';
import { push } from 'connected-react-router';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { PATHS } from 'src/appConfig/paths';
import { Navigator, Toastify } from 'src/services';
import api, { Apis } from 'src/services/api';
import { callApi } from '../commonSagas/callApi';
import { selectCurrentPathname } from '../commonSagas/selectors';
import { toastifyErrorSaga } from '../commonSagas/toastifyFailureSaga';
import { ApiResponse as ApiStatusWrapperResponse } from '../types';
import {
  createSessionLogsAsync,
  getAbsentReasonOptionsSLAsync,
  getContactTypesOptionsSLAsync,
  getSessionTypesOptionsSLAsync,
  getDeliveryTypesOptionsSLAsync,
  getEditableStudentsAsync,
  getLogByIdAsync,
  getLogByIdCloneAsync,
  getOutcomeOptionsSLAsync,
  getPracticeElementOptionsSLAsync,
  getProviderHUserSLAsync,
  getRecentLogsAsync,
  getReferredByOptionsSLAsync,
  getSLHeaderAsync,
  getSupportTypeIdForRelatedServiceAsync,
  getTargetOptionsSLAsync,
  getBehaviorCategoryOptionsSLAsync,
  getBehaviorMeasureOptionsSLAsync,
  removeSessionLogByIdAsync,
  searchActiveProvidersAsync,
  searchLocationAddSLAsync,
  searchLogsAsync,
  searchProvidersAsync,
  searchSchoolsAddSLAsync,
  searchSchoolsAsync,
  searchStudentsAsync,
  updateSessionLogsAsync,
} from './actions';
import { selectCurrentSearchParams, selectMode, selectSubmissionRecordsSL } from './selectors';
import { CreateSessionLogPayload, SearchSessionRequestType, SessionLogCreateResponse, SessionLogDetail } from './types';

function* getDataWithoutParams(api, asyncActionCreator) {
  yield call(callApi, api, {
    asyncAction: asyncActionCreator,
    onFailure: toastifyErrorSaga,
  });
}

function* searchFilters(api, asyncActionCreator, { payload: params }: any) {
  yield call(
    callApi,
    api,
    {
      asyncAction: asyncActionCreator,
      onFailure: toastifyErrorSaga,
    },
    params,
  );
}

function* createSessionLog(api, payload: CreateSessionLogPayload) {
  try {
    const { ok, data: response }: SessionLogCreateResponse = yield call(api, payload);
    if (ok && response?.status === 'success' && response?.data) {
      return response.data;
    } else {
      Toastify.error(`Failed to add log(s): ${response.message}`);
      return null;
    }
  } catch (err) {
    console.log('err: ', err);
    return toastifyErrorSaga(err, err);
  }
}

function* createSessionLogs(api, action: ReturnType<typeof createSessionLogsAsync.request>) {
  try {
    const { payloads } = action.payload;
    const calls = payloads.map(payload => call(createSessionLog, api, payload));

    const successItems: SessionLogDetail[] = yield all(calls);
    if (successItems.filter(i => !!i).length) {
      yield put(
        createSessionLogsAsync.success({
          records: successItems,
          final: action.payload.final,
        }),
      );
      const recordsSubmission: {
        records: SessionLogDetail[];
        final: boolean;
      } = yield select(selectSubmissionRecordsSL);
      if (recordsSubmission.records?.length) {
        if (recordsSubmission.final) {
          Navigator.navigate(PATHS.submissionHistorySessionLog);
        }
        Toastify.success('Session Logs has been successfully submitted');
      }
    } else {
      yield put(createSessionLogsAsync.failure(new Error('Failed to add log(s).')));
    }
  } catch (err) {
    toastifyErrorSaga(new Error('Failed to add log(s).'), err);
    yield put(createSessionLogsAsync.failure(err));
  }
}

function* searchLogs() {
  const apiInstance = api.create().searchLogs;
  const params: SearchSessionRequestType = yield select(selectCurrentSearchParams);
  yield call(
    callApi,
    apiInstance,
    {
      asyncAction: searchLogsAsync,
      onFailure: toastifyErrorSaga,
    },
    params,
  );
}

function* getRecentLogs() {
  const apiInstance = api.create().getRecentSessionLogs;
  yield call(getDataWithoutParams, apiInstance, getRecentLogsAsync);
}

function* handleRefetchLogs() {
  const pathname: string = yield select(selectCurrentPathname);
  if (pathname === PATHS.searchSessionLogs) {
    const params: SearchSessionRequestType = yield select(selectCurrentSearchParams);
    yield put(searchLogsAsync.request(params));
  } else if (pathname === PATHS.sessionLogs) {
    yield put(getRecentLogsAsync.request());
  }
}

function* updateSessionLog(api, payload: ReturnType<typeof updateSessionLogsAsync.request>) {
  try {
    const { ok, data: response }: ApiResponse<ApiStatusWrapperResponse<SessionLogDetail>> = yield call(api, payload);
    if (ok && response?.status === 'success' && response?.data) {
      yield put(
        updateSessionLogsAsync.success({
          records: [response.data],
          final: true,
        }),
      );

      const recordsSubmission: {
        records: SessionLogDetail[];
        final: boolean;
      } = yield select(selectSubmissionRecordsSL);
      if (recordsSubmission.records?.length) {
        if (recordsSubmission.final) {
          Navigator.navigate(PATHS.submissionHistorySessionLog);
        }
        Toastify.success('Update Session Log success.');
      }

      return yield put(push(PATHS.submissionHistorySessionLog));
    } else {
      Toastify.error(`Failed to update log: ${response.message}`);
      return yield put(updateSessionLogsAsync.failure(new Error('Failed to update log(s).')));
    }
  } catch (err) {
    Toastify.error(`Failed to update log: ${err}`);
    return yield put(updateSessionLogsAsync.failure(err));
  }
}

function* navigateToEditSessionLogRoute() {
  const mode = yield select(selectMode);
  if (mode === 'edit' || mode === 'clone') {
    yield put(push(PATHS.editSessionLog));
  }
}

function* removeSessionLog(api, { payload }: ReturnType<typeof removeSessionLogByIdAsync.request>) {
  let response;
  try {
    response = yield call(api, payload);
    yield put(removeSessionLogByIdAsync.success(payload));
    Toastify.success(`Session Log has been successfully removed.`);
    yield call(handleRefetchLogs);
  } catch (err) {
    toastifyErrorSaga(response);
    yield put(removeSessionLogByIdAsync.failure(err));
  }
}

export default function sessionLogsSagas(apiInstance: Apis) {
  return [
    takeLatest(getRecentLogsAsync.request, getRecentLogs),
    takeLatest(
      getTargetOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getTargetOptionsSL,
      getTargetOptionsSLAsync,
    ),
    takeLatest(
      getPracticeElementOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getPracticeOptionsSL,
      getPracticeElementOptionsSLAsync,
    ),
    takeLatest(
      getOutcomeOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getOutcomeOptionsSL,
      getOutcomeOptionsSLAsync,
    ),
    takeLatest(
      getBehaviorCategoryOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getBehaviorCategoryOptionsSL,
      getBehaviorCategoryOptionsSLAsync,
    ),
    takeLatest(
      getBehaviorMeasureOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getBehaviorMeasureOptionsSL,
      getBehaviorMeasureOptionsSLAsync,
    ),
    takeLatest(
      getContactTypesOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getContactOptionsSL,
      getContactTypesOptionsSLAsync,
    ),
    takeLatest(
      getSessionTypesOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getSessionOptionsSL,
      getSessionTypesOptionsSLAsync,
    ),
    takeLatest(
      getBehaviorCategoryOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getBehaviorCategoryOptionsSL,
      getBehaviorCategoryOptionsSLAsync,
    ),
    takeLatest(
      getBehaviorMeasureOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getBehaviorMeasureOptionsSL,
      getBehaviorMeasureOptionsSLAsync,
    ),
    takeLatest(
      getDeliveryTypesOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getDeliveryOptionsSL,
      getDeliveryTypesOptionsSLAsync,
    ),
    takeLatest(
      getReferredByOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getReferredByOptionsSL,
      getReferredByOptionsSLAsync,
    ),
    takeLatest(
      getAbsentReasonOptionsSLAsync.request,
      getDataWithoutParams,
      apiInstance.getStudentAbsentReasonOptionsSL,
      getAbsentReasonOptionsSLAsync,
    ),
    takeLatest(getProviderHUserSLAsync.request, searchFilters, apiInstance.getProviderHUser, getProviderHUserSLAsync),
    takeLatest(
      getSupportTypeIdForRelatedServiceAsync.request,
      searchFilters,
      apiInstance.getSupportTypeIdSL,
      getSupportTypeIdForRelatedServiceAsync,
    ),
    takeLatest(searchProvidersAsync.request, searchFilters, apiInstance.searchSLProviders, searchProvidersAsync),
    takeLatest(
      searchActiveProvidersAsync.request,
      searchFilters,
      apiInstance.searchSLActiveProviders,
      searchActiveProvidersAsync,
    ),
    takeLatest(searchSchoolsAsync.request, searchFilters, apiInstance.searchSLSchools, searchSchoolsAsync),
    takeLatest(searchSchoolsAddSLAsync.request, searchFilters, apiInstance.searchSLSchools, searchSchoolsAddSLAsync),
    takeLatest(
      searchLocationAddSLAsync.request,
      searchFilters,
      apiInstance.searchServiceLocations,
      searchLocationAddSLAsync,
    ),
    takeLatest(searchStudentsAsync.request, searchFilters, apiInstance.searchSLStudents, searchStudentsAsync),
    takeLatest(searchLogsAsync.request, searchLogs),
    takeLatest(getLogByIdAsync.request, searchFilters, apiInstance.getLogById, getLogByIdAsync),
    takeLatest(getLogByIdCloneAsync.request, searchFilters, apiInstance.getLogByIdClone, getLogByIdCloneAsync),
    takeLatest(removeSessionLogByIdAsync.request, removeSessionLog, apiInstance.removeSessionLogById),
    // takeLatest(removeSessionLogByIdAsync.success, handleRemoveLogSuccess),
    takeLatest(
      getEditableStudentsAsync.request,
      searchFilters,
      apiInstance.getEditableStudentsApi,
      getEditableStudentsAsync,
    ),
    takeLatest(createSessionLogsAsync.request, createSessionLogs, apiInstance.createSessionLog),
    takeLatest(updateSessionLogsAsync.request, updateSessionLog, apiInstance.updateSessionLog),
    takeLatest(getLogByIdAsync.success, navigateToEditSessionLogRoute),
    takeLatest(getLogByIdCloneAsync.success, navigateToEditSessionLogRoute),
    takeLatest(getSLHeaderAsync.request, searchFilters, apiInstance.getSLHeader, getSLHeaderAsync),
  ];
}
