import { combineReducers } from 'redux';
import { AsyncState, commonAsyncReducer } from 'src/utils/commonAsyncReducer';
import { isEmpty } from 'src/validations';
import { AsyncActionCreatorBuilder, createReducer } from 'typesafe-actions';
import { ProviderName, SchoolName, ServiceLocation } from '../schedulerRedux/types';
import {
  cancelRemainingStudentsAddingSession,
  clearCompletedStudentSL,
  clearSearchEditableStudents,
  clearSupportTypeId,
  createSessionLogsAsync,
  getAbsentReasonOptionsSLAsync,
  getContactTypesOptionsSLAsync,
  getSessionTypesOptionsSLAsync,
  getDeliveryTypesOptionsSLAsync,
  getEditableStudentsAsync,
  getOutcomeOptionsSLAsync,
  getPracticeElementOptionsSLAsync,
  getProviderHUserSLAsync,
  getReferredByOptionsSLAsync,
  getSLHeaderAsync,
  getSupportTypeIdForRelatedServiceAsync,
  getTargetOptionsSLAsync,
  getBehaviorCategoryOptionsSLAsync,
  getBehaviorMeasureOptionsSLAsync,
  removeSessionLogByIdAsync,
  saveAddLogFormProgress,
  searchActiveProvidersAsync,
  searchLocationAddSLAsync,
  searchSchoolsAddSLAsync,
  setAddSessionTemplate,
  setAddSessionTemplateFirstInfo,
  setFiltersEditableStudent,
  setSelectedProviderAddSession,
  setSelectedStudentsAddSession,
  setSessionDateAddSession,
  updateSessionLogsAsync,
} from './actions';
import {
  AddSessionLogType,
  AddSessionTemplateType,
  BehaviorCategoryType,
  BehaviorMeasureType,
  EditableStudentsType,
  GetEditableStudentsRequest,
  OptionSelectSLType,
  sessionOptionsSelectSLType,
  OutComeType,
  PracticeElementType,
  ProviderHUserId,
  SessionLogDetail,
  SLHeader,
  SupportTypeId,
  TargetAreaType,
} from './types';

export type SearchState = {
  searchActiveProviders: AsyncState<ProviderName[]>;
  searchSchools: AsyncState<SchoolName[]>;
  searchLocations: AsyncState<ServiceLocation[]>;
  targets: AsyncState<TargetAreaType[]>;
  practices: AsyncState<PracticeElementType[]>;
  outcomes: AsyncState<OutComeType[]>;
  progressMeasures: AsyncState<BehaviorCategoryType[]>;
  behaviorMeasures: AsyncState<BehaviorMeasureType[]>;
  deliveryMode: AsyncState<OptionSelectSLType[]>;
  contact: AsyncState<sessionOptionsSelectSLType[]>;
  sessionTypes: AsyncState<sessionOptionsSelectSLType[]>;
  referredBy: AsyncState<OptionSelectSLType[]>;
  absentReason: AsyncState<OptionSelectSLType[]>;
};

export type SubmissionsState = {
  records: SessionLogDetail[];
  final: false;
  loading: boolean;
  error?: Error;
};

const defaultAsyncState = {
  loading: false,
  error: null,
};

const defaultAsyncStateWithDataEmpty = {
  data: [],
  loading: false,
  error: null,
};

export type AddSessionState = {
  provider: ProviderName;
  providerHUserId: AsyncState<ProviderHUserId[]>;
  students: EditableStudentsType[];
  sessionDate: string;
  editableStudents: AsyncState<EditableStudentsType[]> & { filters: GetEditableStudentsRequest };
  template: AddSessionTemplateType;
  progress: Record<number, AddSessionLogType>;
  completedStudentIds: (string | number)[];
  submissions: SubmissionsState;
  search: SearchState;
  editAndClone: AsyncState<any>;
  removeLog: AsyncState<any>;
  supportTypeId: AsyncState<SupportTypeId[]>;
  header: AsyncState<SLHeader[]>;
};

export const initialStateSearch: SearchState = {
  searchActiveProviders: {
    ...defaultAsyncStateWithDataEmpty,
  },
  searchSchools: {
    ...defaultAsyncStateWithDataEmpty,
  },
  searchLocations: {
    ...defaultAsyncStateWithDataEmpty,
  },
  targets: {
    ...defaultAsyncStateWithDataEmpty,
  },
  practices: {
    ...defaultAsyncStateWithDataEmpty,
  },
  outcomes: {
    ...defaultAsyncStateWithDataEmpty,
  },
  progressMeasures: {
    ...defaultAsyncStateWithDataEmpty,
  },
  behaviorMeasures: {
    ...defaultAsyncStateWithDataEmpty,
  },
  deliveryMode: {
    ...defaultAsyncStateWithDataEmpty,
  },
  contact: {
    ...defaultAsyncStateWithDataEmpty,
  },
  sessionTypes: {
    ...defaultAsyncStateWithDataEmpty,
  },
  referredBy: {
    ...defaultAsyncStateWithDataEmpty,
  },
  absentReason: {
    ...defaultAsyncStateWithDataEmpty,
  },
};

export const initialStateAddLog: AddSessionState = {
  provider: null,
  providerHUserId: {
    ...defaultAsyncStateWithDataEmpty,
  },
  students: [],
  sessionDate: null,

  editableStudents: {
    // update this to be a list of students
    filters: null,
    ...defaultAsyncStateWithDataEmpty,
  },

  template: {
    provider: null,
    credentialName: '',
    primaryMethodOfContact: null,
    sessionDate: '',
    startTime: '',
    endTime: '',
    sessionLength: '',
    groupSize: '',
    location: null,
    locationDetails: '',
    deliveryMode: null,
    roomName: '',
    targets: [null],
    practiceElements: [null],
    progressMeasures: [null],
    behaviorMeasures: [null],
    comments: '',
  },
  search: initialStateSearch,

  progress: {},
  completedStudentIds: [],
  submissions: { records: [], final: false, ...defaultAsyncState },
  editAndClone: {
    ...defaultAsyncStateWithDataEmpty,
  },
  removeLog: {
    ...defaultAsyncStateWithDataEmpty,
  },
  supportTypeId: {
    ...defaultAsyncStateWithDataEmpty,
  },
  header: {
    ...defaultAsyncStateWithDataEmpty,
  },
};

export const providerReducer = createReducer<ProviderName>(initialStateAddLog.provider).handleAction(
  setSelectedProviderAddSession,
  (state, { payload }) => ({
    ...payload,
  }),
);

export const providerHUserIdReducer = createReducer<AsyncState<ProviderHUserId[]>>(
  initialStateAddLog.providerHUserId,
).handleAction(
  [getProviderHUserSLAsync.request, getProviderHUserSLAsync.success, getProviderHUserSLAsync.failure],
  (state, action) => ({
    ...state,
    ...commonAsyncReducer<any, any>(getProviderHUserSLAsync)(state, action),
  }),
);

export const studentsReducer = createReducer<EditableStudentsType[]>(initialStateAddLog.students)
  .handleAction(setSelectedStudentsAddSession, (state, { payload }) => [...payload])
  .handleAction(cancelRemainingStudentsAddingSession, (state, action) => {
    const completedStudentIds = [...action.payload];
    const selectedStudents = [...state];
    const removeRemainingStudents = selectedStudents.filter(s => completedStudentIds.includes(s.studentId));
    return removeRemainingStudents;
  });

export const sessionDateReducer = createReducer<string>(initialStateAddLog.sessionDate).handleAction(
  setSessionDateAddSession,
  (state, { payload }) => payload,
);

export const editableStudentsReducer = createReducer<AsyncState<EditableStudentsType[]>>(
  initialStateAddLog.editableStudents,
)
  .handleAction(
    [getEditableStudentsAsync.request, getEditableStudentsAsync.success, getEditableStudentsAsync.failure],
    (state, action) => ({
      ...state,
      ...commonAsyncReducer<any, any>(getEditableStudentsAsync)(state, action),
    }),
  )
  .handleAction(setFiltersEditableStudent, (state, action) => ({
    ...state,
    filters: action.payload,
  }))
  .handleAction(clearSearchEditableStudents, (state, action) => ({
    filters: null,
    ...defaultAsyncStateWithDataEmpty,
  }));

export const templateReducer = createReducer<Record<number, AddSessionLogType>>(initialStateAddLog.template)
  .handleAction(setAddSessionTemplate, (state, { payload }) => ({
    ...payload,
  }))
  .handleAction(setAddSessionTemplateFirstInfo, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

export const progressReducer = createReducer<AddSessionTemplateType>(initialStateAddLog)
  .handleAction(createSessionLogsAsync.success, (state, action) => ({
    ...{},
  }))
  .handleAction(saveAddLogFormProgress, (state, { payload }) => {
    return {
      ...{ ...state, [payload.studentId]: payload.formValues }
  }})
  .handleAction(clearCompletedStudentSL, (state, { payload }) => {
    // const studentId = payload.studentId;
    const completedStudents = { ...state };
    const removedStudents = { ...completedStudents };
    return removedStudents;
  });

export const completedStudentIdsReducer = createReducer<AddSessionState>(initialStateAddLog)
  // .handleAction(createSessionLogsAsync.success, (state, action) => [])
  .handleAction(saveAddLogFormProgress, (state, { payload }) => {
    const completedStudentIds =
      payload.completed && !state.includes(payload.studentId) ? [...state, payload.studentId] : state;
    return [...completedStudentIds];
  })
  .handleAction(clearCompletedStudentSL, (state, { payload }) => {
    const studentId = payload.studentId;
    const completedStudents = [...state];
    const removedStudents = completedStudents.filter((student, index) => student !== studentId);

    return [...removedStudents];
  });

export const submissionsReducer = createReducer<SubmissionsState>(initialStateAddLog.submissions)
  .handleAction(createSessionLogsAsync.request, (state, action) => ({
    ...state,
    loading: true,
    final: action.payload.final,
  }))
  .handleAction(createSessionLogsAsync.success, (state, action) => ({
    ...state,
    loading: false,
    records: [...state.records, ...action.payload.records],
  }))
  .handleAction(createSessionLogsAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(updateSessionLogsAsync.success, (state, action) => {
    let stateRecords = [...state.records];
    let actionRecords = [...action.payload.records];
    let deDuplicateRecords = actionRecords.filter((record, index) => {
      return stateRecords.find(submission => submission.planId === record.planId);
    });
    return {
      ...state,
      loading: false,
      records: isEmpty(deDuplicateRecords) ? [...state.records, ...action.payload.records] : deDuplicateRecords,
    };
  })
  .handleAction(removeSessionLogByIdAsync.success, (state, action) => {
    return {
      ...state,
      loading: false,
      records: state.records.filter((r: SessionLogDetail) => r.sessionId !== action.payload.sessionId),
    };
  });

const searchReducer = (searchAsyncAction: AsyncActionCreatorBuilder<any, any, any, any>, initial) =>
  createReducer(initial).handleAction(
    [searchAsyncAction.request, searchAsyncAction.success, searchAsyncAction.failure, searchAsyncAction.cancel],
    (state, action) => ({
      ...state,
      ...commonAsyncReducer<any, any>(searchAsyncAction)(state.initial, action),
    }),
  );
export const removeSessionLogReducer = createReducer(initialStateAddLog.removeLog).handleAction(
  [removeSessionLogByIdAsync.request, removeSessionLogByIdAsync.success, removeSessionLogByIdAsync.failure],
  commonAsyncReducer(removeSessionLogByIdAsync, 'data'),
);

export const supportTypeIdReducer = createReducer<AsyncState<SupportTypeId[]>>(initialStateAddLog.supportTypeId)
  .handleAction(
    [
      getSupportTypeIdForRelatedServiceAsync.request,
      getSupportTypeIdForRelatedServiceAsync.success,
      getSupportTypeIdForRelatedServiceAsync.failure,
    ],
    (state, action) => ({
      ...state,
      ...commonAsyncReducer<any, any>(getSupportTypeIdForRelatedServiceAsync)(state, action),
    }),
  )
  .handleAction(clearSupportTypeId, (state, action) => {
    return {
      ...defaultAsyncStateWithDataEmpty,
    };
  });

const getOptionsReducer = (searchAsyncAction: AsyncActionCreatorBuilder<any, any, any>, initial) =>
  createReducer(initial).handleAction(
    [searchAsyncAction.request, searchAsyncAction.success, searchAsyncAction.failure],
    (state, action) => ({
      ...state,
      ...commonAsyncReducer<any, any>(searchAsyncAction)(state.initial, action),
    }),
  );

export const slHeaderReducer = createReducer<AsyncState<SLHeader[]>>(initialStateAddLog.header)
  .handleAction([getSLHeaderAsync.request, getSLHeaderAsync.success, getSLHeaderAsync.failure], (state, action) => ({
    ...state,
    ...commonAsyncReducer<any, any>(getSLHeaderAsync)(state, action),
  }))
  .handleAction([getSLHeaderAsync.cancel], (state, action) => ({
    ...defaultAsyncStateWithDataEmpty,
  }));

const searchCombineReducer = combineReducers<SearchState>({
  searchActiveProviders: searchReducer(searchActiveProvidersAsync, initialStateSearch.searchActiveProviders),
  searchSchools: searchReducer(searchSchoolsAddSLAsync, initialStateSearch.searchSchools),
  searchLocations: searchReducer(searchLocationAddSLAsync, initialStateSearch.searchLocations),
  targets: getOptionsReducer(getTargetOptionsSLAsync, initialStateSearch.targets),
  practices: getOptionsReducer(getPracticeElementOptionsSLAsync, initialStateSearch.practices),
  outcomes: getOptionsReducer(getOutcomeOptionsSLAsync, initialStateSearch.outcomes),
  progressMeasures: getOptionsReducer(getBehaviorCategoryOptionsSLAsync, initialStateSearch.progressMeasures),
  behaviorMeasures: getOptionsReducer(getBehaviorMeasureOptionsSLAsync, initialStateSearch.behaviorMeasures),
  deliveryMode: getOptionsReducer(getDeliveryTypesOptionsSLAsync, initialStateSearch.deliveryMode),
  contact: getOptionsReducer(getContactTypesOptionsSLAsync, initialStateSearch.contact),
  sessionTypes: getOptionsReducer(getSessionTypesOptionsSLAsync, initialStateSearch.sessionTypes),
  referredBy: getOptionsReducer(getReferredByOptionsSLAsync, initialStateSearch.referredBy),
  absentReason: getOptionsReducer(getAbsentReasonOptionsSLAsync, initialStateSearch.absentReason),
});

export default combineReducers<AddSessionState>({
  provider: providerReducer,
  providerHUserId: providerHUserIdReducer,
  students: studentsReducer,
  sessionDate: sessionDateReducer,
  editableStudents: editableStudentsReducer,
  template: templateReducer,
  search: searchCombineReducer,
  progress: progressReducer,
  completedStudentIds: completedStudentIdsReducer,
  submissions: submissionsReducer,
  editAndClone: getOptionsReducer(updateSessionLogsAsync, initialStateAddLog.editAndClone),
  removeLog: getOptionsReducer(removeSessionLogByIdAsync, initialStateAddLog.removeLog),
  supportTypeId: supportTypeIdReducer,
  header: slHeaderReducer,
});
