import { Button, Grid, MenuItem, Popover } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormikErrors, FormikProps } from 'formik';
import React from 'react';
import { FaCaretDown, FaUsers } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { PATHS } from 'src/appConfig/paths';
import { useConfirmDialog } from 'src/components/common/ConfirmDialog';
import PageContentContainer, { PageContentFooter } from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import CancelDialog from 'src/containers/TieredServicesContainers/Components/CancelDialog';
import { selectUserPermissions } from 'src/redux/authRedux/selectors';
import { IRootState } from 'src/redux/rootReducer';
import { ProviderName } from 'src/redux/schedulerRedux/types';
import {
  cancelAddingSession,
  cancelRemainingStudentsAddingSession,
  clearCompletedStudentSL,
  clearHStudentId,
  clearSupportTypeId,
  createSessionLogsAsync,
  getAbsentReasonOptionsSLAsync,
  getContactTypesOptionsSLAsync,
  getSessionTypesOptionsSLAsync,
  getDeliveryTypesOptionsSLAsync,
  getOutcomeOptionsSLAsync,
  getPracticeElementOptionsSLAsync,
  getReferredByOptionsSLAsync,
  getSLHeaderAsync,
  getSupportTypeIdForRelatedServiceAsync,
  getTargetOptionsSLAsync,
  getBehaviorCategoryOptionsSLAsync,
  getBehaviorMeasureOptionsSLAsync,
  saveAddLogFormProgress,
  searchStudentsAsync,
  setAddSessionTemplateFirstInfo,
  setSelectedProviderAddSession,
  setSessionDateAddSession,
  searchLocationAddSLAsync,
} from 'src/redux/sessionLogsRedux/actions';
import { AddSessionLogType, EditableStudentsType } from 'src/redux/sessionLogsRedux/types';
import { DefaultRoute, Navigator, Toastify } from 'src/services';
import { LOCAL_STORAGE_SUBMIT_CLONE } from 'src/services/submitCloneNewSL';
import { getFullName } from 'src/utils/nameUtils';
import { isEmpty } from 'src/validations';
import AddSessionDialog from '../AddProviderAndStudentsDialog';
import AddSessionLogFormik, { ConnectedAddSessionLogForm } from '../components/SessionLogForm';
import SkeletonForm from '../components/SkeletonForm';
import SLStudentInfoBanner from '../components/StudentInfoBanner';
import { AddSessionLogFormSchema, isCrisisOrConsultation, formValuesToPayload, scrollToTop } from './helper';
import './styles.scss';
import moment from 'moment';

const clsPrefix = 'add-session-logs';
let cancelling = false;
let submitClone = false;
let multiple = false;
let multiple2 = false;
let submitted = false;
let submitCloneVal = '';

const AddSessionLog: React.FC<Props> = ({
  permissions,
  provider,
  providerHUserId,
  students,
  clearHStudentId,
  searchStudents,
  cancelAddingSession,
  cancelRemainingStudents,
  templateForm,
  saveFormProgress,
  completedStudentIds,
  formProgress,
  createSessionLogs,
  submissions,
  hStudentId,
  clearSupportTypeId,
  loading,
  contact,
  sessionTypes,
  deliveryMode,
  outcomes,
  practices,
  targets,
  progressMeasures,
  behaviorMeasures,
  absentReason,
  referredBy,
  getTargetOptions,
  getPracticeOptions,
  getOutcomeOptions,
  getContactOptions,
  getSessionOptions,
  getBehaviorCategoryOptions,
  getBehaviorMeasureOptions,
  getDeliveryModeOptions,
  getReferredByOptions,
  getAbsentReasonOptions,
  supportTypeId,
  getSupportTypeId,
  studentInfo,
  getSLHeader,
  clearSLHeader,
  studentInfoLoading,
  clearCompletedStudentSL,
  location,
  searchSLLocation,
  clearSLLocation,
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [showCancelDialog, setShowCancelDialog] = React.useState<boolean>(false);

  const [ConfirmCancelCompletedDialog, confirmCancelCompletedStudent] = useConfirmDialog(
    'You have unsaved information. Are you sure you want to leave this page?',
  );

  const [ConfirmCancelOneStudentDialog, confirmCancelOneStudent] = useConfirmDialog(
    'You have unsaved information. Are you sure you want to leave this page?',
  );

  const [ConfirmCancelAllDialog, confirmCancelAll] = useConfirmDialog(
    'Are you sure you want to cancel all, including ready to submit?',
  );

  const [ConfirmCancelRemainingDialog, confirmCancelRemaining] = useConfirmDialog(
    'Are you sure you want to cancel this log and remaining students?',
  );
  
  //same
  React.useEffect(() => {
    if (!permissions.isSL) {
      Toastify.error('You do NOT have permission to access SESSION LOGS function.');
      Navigator.navigate(DefaultRoute.getLocalDefaultRoute());
    }
  }, []);

  //same
  React.useEffect(() => {
    if (isEmpty(provider) || isEmpty(students)) {
      Navigator.navigate(PATHS.sessionLogs);
    }
  }, [provider, students]);

  // save local storage value depending if submit & clone
  // button was pressed
  React.useEffect(() => {
    if (submitCloneVal === 'true' || submitCloneVal === 'false') {
      localStorage.setItem(LOCAL_STORAGE_SUBMIT_CLONE, submitCloneVal);
    }
  }, [submitCloneVal, localStorage]);

  const [showAddSessionDialog, setShowAddSessionDialog] = React.useState<boolean>(false);
  const [currentStudentIndex, setCurrentStudentIndex] = React.useState<number>(0);
  const [nextStudentIndex, setNextStudentIndex] = React.useState<number>(0);
  const [studentToCopy, setStudentToCopy] = React.useState<EditableStudentsType>(null);

  const [remaining, setRemaining] = React.useState<number>(0);
  const [submitActionType, setSubmitActionType] = React.useState<'save_next' | 'submit_clone'>('save_next');
  const [completedStudentListAnchor, setCompletedStudentListAnchor] = React.useState<HTMLButtonElement>(null);
  const [showSkeleton, setShowSkeleton] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (showSkeleton) {
      setTimeout(() => {
        setShowSkeleton(false);
      }, 500);
    }
  }, [showSkeleton]);

  React.useEffect(() => {
    if (isEmpty(targets.data)) {
      getTargetOptions();
    }
    if (isEmpty(practices.data)) {
      getPracticeOptions();
    }
    if (isEmpty(outcomes.data)) {
      getOutcomeOptions();
    }
    if (isEmpty(progressMeasures.data)) {
      getBehaviorCategoryOptions();
    }
    if (isEmpty(behaviorMeasures.data)) {
      getBehaviorMeasureOptions();
    }
    if (isEmpty(contact.data)) {
      getContactOptions();
    }
    if (isEmpty(sessionTypes.data)) {
      getSessionOptions();
    }
    if (isEmpty(deliveryMode.data)) {
      getDeliveryModeOptions();
    }
    if (isEmpty(referredBy.data)) {
      getReferredByOptions();
    }
    if (isEmpty(absentReason.data)) {
      getAbsentReasonOptions();
    }
  }, []);

  React.useEffect(() => {
    if (!templateForm.location && students[currentStudentIndex]) {
      clearSLLocation();
      searchSLLocation(students[currentStudentIndex]?.schoolName);
    }
  }, [currentStudentIndex]);

  // Loads initial 'location' field based on student's school
  React.useEffect(() => {
    if (location && !formValue.location) {
      setFormValue({
        ...formValue,
        location: location[0],
      });
    }
  }, [location]);

  const initFormValue: AddSessionLogType = {
    student: students[currentStudentIndex],
    hStudentId: hStudentId,
    studentNotAvailable: false,
    reasonStudentNotAvailable: '',
    provider: provider,
    providerId: students[currentStudentIndex]?.providerId,
    providerHUserId: providerHUserId,
    credentialName: templateForm.credentialName,
    primaryMethodOfContact: templateForm.primaryMethodOfContact,
    sessionType: templateForm.sessionType,
    referredBy: '',
    referredOtherDescription: '',
    sessionDate: templateForm.sessionDate,
    startTime: templateForm.startTime,
    endTime: templateForm.endTime,
    sessionLength: templateForm.sessionLength,
    groupSize: templateForm.groupSize,
    location: templateForm.location,
    locationDetails: templateForm.locationDetails,
    deliveryMode: templateForm.deliveryMode,
    roomName: templateForm.roomName,
    targets: templateForm.targets,
    outcomes: templateForm.practiceElements.map(() => null),
    practiceElements: templateForm.practiceElements,
    behaviorMeasures: templateForm.behaviorMeasures,
    progressMeasures: templateForm.progressMeasures,
    notes: templateForm.practiceElements.map(() => ''),
    comments: templateForm.comments,
    licenseAttestUserId: null,
    licenseAttestName: null,
    licenseAttestDate: null,
    supportTypeId: null,
  };

  const [formValue, setFormValue] = React.useState<AddSessionLogType>(initFormValue);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const formRef = React.useRef<FormikProps<AddSessionLogType>>(null);

  const history = useHistory();
  React.useEffect(() => {
    history.listen(({ pathname }) => {

      //resets all variables
      if ( submitted ) {
        multiple = false;
        multiple2 = false;
        cancelling = false;
        submitClone = false;
        if (pathname === PATHS.addSessionLog) {
          submitted = false;
        }
      }
      // case if user navigates away from creating SL -> will take them back to add SL
      else if ( (pathname === PATHS.sessionLogs) && (!isEmpty(students)) && (!cancelling) ) {
        //if there were multiple students selected
        if (students.length > 1) {
          multiple = true;
          cancelAddingSession();
          students = [];
          Navigator.navigate(PATHS.sessionLogs);
          //Navigator.navigate(PATHS.addSessionLogTemplate);
        }
        //only one student was selected
        else {
          cancelAddingSession();
          students = [];
          Navigator.navigate(PATHS.sessionLogs);
        }
        cancelling = true;
        submitClone = false;
      }
      else if (pathname === PATHS.submissionHistorySessionLog) {
        cancelling = false;
        multiple = false;
        multiple2 = false;
        submitted = false;
      }
      else if ( (pathname === PATHS.addSessionLog) && (cancelling) ) {
        cancelling = false;
        submitted = false;
      }
      else if(submitClone && (pathname === PATHS.scheduler || pathname === PATHS.tieredService) ){
        cancelAddingSession();
        students = [];
      }
      //case if user navigates away from creating SL more than once
      else if( (pathname === PATHS.sessionLogs) && (!isEmpty(students)) && (multiple2) ){
        if( (pathname !== PATHS.submissionHistorySessionLog) ) {
          multiple = true;
          Navigator.navigate(PATHS.addSessionLog);
        } 
      }
    });
  }, [history, PATHS]);

  React.useEffect(() => {
    if (hStudentId) {
      setFormValue({
        ...formValue,
        hStudentId: hStudentId,
      });
    } else {
        // Filter out commas from the student's last name for search
        if (students[currentStudentIndex].lastName.includes(',')) {
          students[currentStudentIndex].lastName = students[currentStudentIndex].lastName.split(',')[0]
        }
      // call student search again  
      !!students.length &&
        searchStudents(`${students[currentStudentIndex].lastName}, ${students[currentStudentIndex].firstName} `);
    }
  }, [hStudentId]);

  React.useEffect(() => {
    handleChangeSupportTypeId();
  }, [supportTypeId, currentStudentIndex]);

  const handleChangeSupportTypeId = () => {
    const studentIsSupportTypeService = students[currentStudentIndex]?.supportType === 'service';
    if (supportTypeId) {
      studentIsSupportTypeService
        ? setFormValue({
            ...formValue,
            supportTypeId: supportTypeId,
          })
        : setFormValue({
            ...formValue,
            supportTypeId: null,
          });
    } else {
      studentIsSupportTypeService &&
        getSupportTypeId({
          supportId: students[currentStudentIndex]?.supportId,
          supportType: students[currentStudentIndex]?.supportType,
        });
    }
  };

  React.useEffect(() => {
    clearSLHeader();
    students[currentStudentIndex]?.supportType !== 'unsched' && handleHeader();
  }, [currentStudentIndex, students]);

  const handleHeader = () => {
    const sessionIdTemp = null;
    getSLHeader({
      sessionId: sessionIdTemp,
      supportId: students[currentStudentIndex]?.supportId,
      supportType: students[currentStudentIndex]?.supportType,
    });
  };

  //same
  React.useEffect(() => {
    if (students && !multiple) {

      const nextIndex = students.findIndex(
        (s, index) => index !== currentStudentIndex && !completedStudentIds.includes(s.studentId),
      );

      if ((currentStudentIndex > 0 && currentStudentIndex > students.length - 1) || currentStudentIndex === -1) {
        const lastCompletedIndex = students.findIndex(
          s => s.studentId === completedStudentIds[completedStudentIds.length - 1],
        );
        setCurrentStudentIndex(nextIndex !== -1 ? nextIndex : lastCompletedIndex);
      } else {
        setNextStudentIndex(nextIndex);
      }

      let remain = students.length - completedStudentIds.length;
      remain = nextIndex !== -1 ? remain : remain - 1;
      remain = remain > -1 ? remain : 0;
      setRemaining(remain);
      setSubmitActionType(remain > 0 ? 'save_next' : 'submit_clone');
    }
    else if (multiple && students) {
      completedStudentIds = null;
      multiple = false;
      multiple2 = true;

      const studentSelectedIndex = students[0].studentId;
      setCurrentStudentIndex(studentSelectedIndex);
      const nextIndex = students.findIndex(
        (s, index) => index !== currentStudentIndex,
      );

      setNextStudentIndex(nextIndex);

      let remain = students.length;
      setRemaining(remain);
      setSubmitActionType(remain > 0 ? 'save_next' : 'submit_clone');
    }
  }, [currentStudentIndex, nextStudentIndex, students, completedStudentIds]);

  const closeAddStudentDialog = () => setShowAddSessionDialog(false);

  const handleOnCreateSessionLog = (provider: ProviderName, students: EditableStudentsType[], sessionDate) => {
    setSelectedProviderAddSession(provider);
    setSessionDateAddSession(sessionDate);
    setAddSessionTemplateFirstInfo({
      provider: provider,
      credentialName: provider?.credentialName,
      sessionDate: sessionDate,
    });
    // handleHeader();
    closeAddStudentDialog();
  };

  const getClickCount = () => {
    return localStorage.getItem("clickCount");
  };

  const setClickCount = (val: number) => {
    const curr = parseInt(getClickCount());
    var count = (curr) + (val);
    localStorage.setItem("clickCount", String(count));
  };

  const handleCancelOneStudent = () => {
    submitCloneVal = 'false';
    localStorage.removeItem(LOCAL_STORAGE_SUBMIT_CLONE);
    localStorage.removeItem('ProjectedEndDate');
    localStorage.removeItem('ProjectedStartDate');
    confirmCancelOneStudent(() => {
      cancelAddingSession();
      cancelling = true;
      multiple2 = false;
      Navigator.navigate(PATHS.sessionLogs);
    });
  };

  const handleCancelAll = () => {
    submitCloneVal = 'false';
    localStorage.removeItem(LOCAL_STORAGE_SUBMIT_CLONE);
    localStorage.removeItem('ProjectedEndDate');
    localStorage.removeItem('ProjectedStartDate');
    confirmCancelAll(() => {
      cancelAddingSession();
      cancelling = true;
      multiple2 = false;
      Navigator.navigate(PATHS.sessionLogs);
    });
  };

  const handleCancelRemaining = () => {
    submitCloneVal = 'false';
    localStorage.removeItem(LOCAL_STORAGE_SUBMIT_CLONE);
    localStorage.removeItem('ProjectedEndDate');
    localStorage.removeItem('ProjectedStartDate');
    if (students.length > 1) {
      confirmCancelRemaining(() => {
        cancelRemainingStudents(completedStudentIds);
        setShowCancelDialog(false);
        handleSubmitWhenCancelRemaining();
        cancelling = true;
        multiple2 = false;
        Navigator.navigate(PATHS.sessionLogs);
      });
    } else {
      handleCancelAll();
    }
  };

  const resetForm = () => {
    formRef.current.resetForm();
  };
  //
  const handleSaveForm = (formValues: AddSessionLogType, completed: boolean) => {
    saveFormProgress({
      studentId: studentToCopy?.studentId || students[currentStudentIndex]?.studentId,
      formValues,
      completed,
    });
  };

  const handleSubmitAndClone = () => {
    cancelling = true;
    submitClone = true;
    submitCloneVal = 'true';
    localStorage.removeItem('ProviderSignatureError');
    // set student to clone
    if (!studentToCopy) {
      setStudentToCopy(students[currentStudentIndex]);
    }

    const hasSubmittedAllStudents = !!submissions.records.length;
    if (hasSubmittedAllStudents) {
      // submit only last student payload this time
      createSessionLogs({
        payloads: getLastStudentPayload(),
        final: false,
      });
    } else {
      // then submit all
      createSessionLogs({
        payloads: getAllStudentsPayloads(),
        final: false,
      });
    }
  };

  const handleNext = (formValues: AddSessionLogType) => {
    submitCloneVal = 'false';
    localStorage.setItem('ProviderSignatureError', 'false');
    handleSaveForm(formValues, true);

    if(localStorage.getItem("isCompleted") === "true") setClickCount(-1);
    
    if(parseInt(getClickCount()) === 0) localStorage.setItem("isCompleted", "false");

    if (submitActionType === 'save_next') {
      setSubmitting(true);

      clearSupportTypeId();
      clearHStudentId();

      setFormValue(initFormValue);
      setCurrentStudentIndex(nextStudentIndex);

      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
    } else {
      handleSubmitAndClone();
    }
  };

  const getAllStudentsPayloadsForSubmitWhenCancel = () => {
    const formLastStudentCompleted = formRef.current.isValid && !formRef.current.touched;
    const formValues = {
      ...formProgress,
      [students[currentStudentIndex].studentId]: formRef.current.values,
    };
    const payloads = formLastStudentCompleted
      ? Object.keys(formValues).map(studentId => formValuesToPayload(formValues[studentId], students, studentId))
      : // handle cancel remaining students
        Object.keys(formProgress).map(studentId => formValuesToPayload(formProgress[studentId], students, studentId));

    return payloads;
  };

  const getAllStudentsPayloads = () => {
    const formValues = {
      ...formProgress,
      [students[currentStudentIndex].studentId]: formRef.current.values,
    };

    const payloads = Object.keys(formValues).map(studentId =>
      formValuesToPayload(formValues[studentId], students, studentId),
    );

    return payloads;
  };

  const getLastStudentPayload = () => {
    const formValues = formRef.current.values;
    const payload = formValuesToPayload(formValues, students, studentToCopy.studentId);
    return [payload];
  };

  const handleSubmitWhenCancelRemaining = () => {
    submitted = true;
    submitCloneVal = 'false';

    // LocalStorage Clean-up
    localStorage.removeItem(LOCAL_STORAGE_SUBMIT_CLONE);
    localStorage.removeItem('ProjectedEndDate');
    localStorage.removeItem('ProjectedStartDate');

    const payloads = getAllStudentsPayloadsForSubmitWhenCancel();
    if (!isEmpty(payloads)) {
      createSessionLogs({
        payloads: getAllStudentsPayloadsForSubmitWhenCancel(),
        final: true,
      });
    }

    if (studentToCopy) {
      setStudentToCopy(null);
    }

  };

  // submit when all students are filled
  const handleSubmit = () => {
    submitted = true;
    submitCloneVal = 'false';

    // LocalStorage clean-up
    localStorage.removeItem(LOCAL_STORAGE_SUBMIT_CLONE);
    localStorage.removeItem('ProjectedEndDate');
    localStorage.removeItem('ProjectedStartDate');

    handleSaveForm(formRef.current.values, true);
    const hasSubmittedAllStudents = !!submissions.records.length;

    createSessionLogs({
      payloads: hasSubmittedAllStudents ? getLastStudentPayload() : getAllStudentsPayloads(),
      final: true,
    });

    if (studentToCopy) {
      setStudentToCopy(null);
    }

  };

  const handleCompletedStudentSelected = (id: number) => {
    setCompletedStudentListAnchor(null);
    confirmCancelCompletedStudent(() => {
      onYes();
    });

    const onYes = () => {
      // set current student to selected
      setSubmitting(true);
      setShowSkeleton(true);

      const studentSelectedIndex = students.findIndex(s => s.studentId === id);

      setCurrentStudentIndex(studentSelectedIndex);
      clearCompletedStudentSL({ studentId: id });

      // set form init of selected student
      const progress = formProgress[students[studentSelectedIndex].studentId];

      localStorage.setItem("isCompleted", "true");
      setClickCount(1);

      scrollToTop(clsPrefix);
      resetForm();

      setFormValue(progress);

      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
    };
  };

  const shouldDisableSubmitButton = (isFormValid: boolean, isFormDirty: boolean) => {
    // State loading when Create log
    if (submitting || submissions.loading) {
      return true;
    }

    if (formRef.current?.values?.licenseAttestUserId === null) {
      if(Object.keys(formRef.current.errors).length === 0) {
        localStorage.setItem('ProviderSignatureError', "true");
      }
      else {
        localStorage.setItem('ProviderSignatureError', "false");
      } 
      return true;
    }
    if (formRef.current?.values?.licenseAttestUserId) {
      localStorage.setItem('ProviderSignatureError', "false");
    }

    if(isCloning && !isFormDirty) return false;

    if (remaining === 0) return !(isFormValid && isFormDirty);

    return !students?.length || students.length !== completedStudentIds.length;
  };

  const shouldDisableNextButton = (isFormValid: boolean, isFormDirty: boolean) => {
    if (submitting || loading) {
      return true;
    }

    if (formRef.current?.values?.licenseAttestUserId === null) {
      if(Object.keys(formRef.current.errors).length === 0) {
        localStorage.setItem('ProviderSignatureError', "true");
      }
      else {
        localStorage.setItem('ProviderSignatureError', "false");
      } 
      return true;
    }
    
    if (isCloning && isFormValid) { 
      return false; }

    if (isFormValid && !isFormDirty) {
      return false;
    }
    if(isFormValid) {
      return false;
    }

    else return !(isFormValid && isFormDirty);
  };

  const validate = ({
    targets,
    practiceElements,
    outcomes,
    sessionDate,
    studentNotAvailable,
    primaryMethodOfContact,
  }: AddSessionLogType): FormikErrors<AddSessionLogType> => {
    const errors: FormikErrors<AddSessionLogType> = {
      targets: [],
      practiceElements: [],
      outcomes: [],
    };

    // validate session date (for submit & clone SL's)
    const startDate = studentInfo[0]?.supportStartDate;
    const endDate = studentInfo[0]?.supportEndDate;
    const today = moment();
    sessionDate = moment(sessionDate).format('MM/DD/YYYY');

    if (!moment(sessionDate).isValid()) {
      errors.sessionDate = 'Please enter a valid date (MM/DD/YYYY)';
    } else {
      if (endDate && startDate) {
        if (moment(sessionDate).isAfter(moment(endDate).format('MM/DD/YYYY'))) {
          errors.sessionDate = `Session Date must be on or within the (Projected) Start Date and (Projected) End Date`;
        }
        else if (moment(sessionDate).isBefore(moment(startDate).format('MM/DD/YYYY'))) {
          errors.sessionDate = `Session Date must be on or within the (Projected) Start Date and (Projected) End Date`;
        }
      }
      if (moment(sessionDate).isAfter(today)) {
        errors.sessionDate = `Session Date cannot be in the future`;
      }
    }
    
    if (!studentNotAvailable) {
      for (let i = 0; i < targets?.length; i++) {
        (errors.targets as Array<unknown>).push(
          !targets[i]?.id
            ? { id: `Target is required` }
            : {
                id: null,
                description:
                  targets[i].needDescription && !targets[i].description ? `Target Description is required` : null,
              },
        );
        (errors.practiceElements as Array<unknown>).push(
          !practiceElements[i]?.id
            ? { id: `Practice Element is required` }
            : {
                id: null,
                description:
                  practiceElements[i].needDescription && !practiceElements[i].description ? `Practice Element Description is required` : null,
              },
        );
        (errors.outcomes as string[]).push(!outcomes?.[i] && !isCrisisOrConsultation(primaryMethodOfContact) ? `Outcome is required` : null);
      }

      if (!(errors.targets as Array<{ id; description }>).some(err => !!err.id || !!err.description)) {
        delete errors.targets;
      }

      if (!(errors.practiceElements as Array<{ id; description }>).some(err => !!err.id || !!err.description)) {
        delete errors.practiceElements;
      }

      if (!(errors.outcomes as Array<unknown>).some(err => !!err)) {
        delete errors.outcomes;
      }
    } else {
      delete errors.targets;
      delete errors.practiceElements;
      delete errors.outcomes;
    }

    return errors;
  };
  
  const isCloning = !!studentToCopy;

  return (
    <>
      {showSkeleton && <SkeletonForm />}
      {!showSkeleton && (
        <AddSessionLogFormik
          initialValues={formValue}
          validationSchema={AddSessionLogFormSchema}
          onSubmit={handleNext}
          innerRef={formRef}
          validate={validate}
          isInitialValid={ (isCloning || localStorage.getItem("isCompleted") === "true")  ? true : false}
          enableReinitialize>
          {(formProps: FormikProps<AddSessionLogType>) => {
            // console.log('formProps values: ', formProps.values);
            // console.log('formProps errors: ', formProps.errors);

            return (
              <Element name={`${clsPrefix}`}>
                <PageContentContainer className={`${clsPrefix}-page-container`} loading={submitting || loading}>
                  <StickyPageHeader>
                    <div className={`${clsPrefix}-header-container`}>
                      <h3>Add Session Log</h3>
                      <Grid container spacing={isMobile ? 2 : 1} className={`${clsPrefix}-header-status`}>
                        <Grid item xs={12} md={8} className={`${clsPrefix}-header-status-left`}>
                          {!!students?.length && (
                            <span className={`${clsPrefix}-header-status-text`}>
                              # Students Remaining: <b>{remaining > 0 ? remaining - 1 : 0}</b>{' '}
                              {/* hotfix: ECSSS-23253 */}
                              <>
                                {isMobile && <br />}
                                {nextStudentIndex !== -1 && (
                                  <>
                                    {' | '}Next Student:{' '}
                                    <b>
                                      {students[nextStudentIndex]?.firstName} {students[nextStudentIndex]?.lastName}
                                    </b>
                                  </>
                                )}
                              </>
                            </span>
                          )}
                          <Button
                            className={`${clsPrefix}-header-status-button`}
                            variant="text"
                            startIcon={<FaUsers />}
                            color="secondary"
                            onClick={() => setShowAddSessionDialog(true)}>
                            ADD/REMOVE STUDENTS
                          </Button>
                        </Grid>

                        {!!completedStudentIds?.length && localStorage.getItem(LOCAL_STORAGE_SUBMIT_CLONE) === "false" && (
                          <Grid item xs={12} md={4} className={`${clsPrefix}-header-status-right`}>
                            <Button
                              variant="text"
                              endIcon={<FaCaretDown />}
                              color="secondary"
                              onClick={({ currentTarget }) => setCompletedStudentListAnchor(currentTarget)}>
                              READY TO SUBMIT
                            </Button>
                            <Popover
                              open={!!completedStudentListAnchor}
                              anchorEl={completedStudentListAnchor}
                              onClose={() => setCompletedStudentListAnchor(null)}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              classes={{
                                paper: `${clsPrefix}-header-status-done-list`,
                              }}>
                              {completedStudentIds.map(id => {
                                const s = students.find(s => s.studentId === id);
                                const fullName = getFullName(s?.firstName, s?.lastName);
                                return (
                                  <MenuItem
                                    key={id}
                                    onClick={() => handleCompletedStudentSelected(id as any)}
                                    selected={id === students[currentStudentIndex]?.studentId}>
                                    {fullName}
                                  </MenuItem>
                                );
                              })}
                            </Popover>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </StickyPageHeader>
                  {students && (
                    <SLStudentInfoBanner
                      student={
                        students[currentStudentIndex]?.supportType === 'unsched'
                          ? students[currentStudentIndex]
                          : {
                              studentId: studentInfo[0]?.studentId,
                              studentExtId: studentInfo[0]?.studentExtId,
                              lastName: studentInfo[0]?.studentLastName,
                              firstName: studentInfo[0]?.studentFirstName,
                              schoolCode: studentInfo[0]?.schoolCode,
                              schoolName: studentInfo[0]?.studentSchoolName,
                              grade: studentInfo[0]?.grade,
                              birthDate: studentInfo[0]?.birthDate,
                              gender: studentInfo[0]?.gender,
                              supportName: studentInfo[0]?.supportTypeName,
                              supportType: studentInfo[0]?.supportType || students[currentStudentIndex]?.supportType,
                              esy: studentInfo[0]?.serviceEsy,
                              frequency: studentInfo[0]?.frequency,
                              startDate: studentInfo[0]?.supportStartDate,
                              endDate: studentInfo[0]?.supportEndDate,
                              primaryTargetArea: studentInfo[0]?.targetArea,
                              supportActiveFlag: studentInfo[0]?.supportActiveFlag,
                              providerId: students[currentStudentIndex]?.providerId,
                            }
                      }
                      loading={studentInfoLoading}
                    />
                  )}
                  <ConnectedAddSessionLogForm
                    {...formProps}
                    studentId={students[currentStudentIndex]?.studentId}
                    key={students[currentStudentIndex]?.studentId || 0}
                  />
                  <PageContentFooter>
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        students.length > 1 ? setShowCancelDialog(true) : handleCancelOneStudent();
                      }}>
                      CANCEL
                    </Button>

                    {( students?.length === 1 && (!!students?.length || !!studentToCopy)) && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        type="submit"
                        onClick={() => {
                          formProps.handleSubmit();
                          scrollToTop(clsPrefix);
                        }}
                        disabled={shouldDisableNextButton(formProps.isValid, formProps.dirty)}>
                        {submitActionType === 'save_next' ? 'NEXT' : 'Submit & Clone' }
                      </Button>
                    )}

                    {( students?.length > 1 && submitActionType !== 'submit_clone' && (!!students?.length || !!studentToCopy)) && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        type="submit"
                        onClick={() => {
                          formProps.handleSubmit();
                          scrollToTop(clsPrefix);
                        }}
                        disabled={shouldDisableNextButton(formProps.isValid, formProps.dirty)}>
                        {submitActionType === 'save_next' ? 'NEXT' : '' }
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={shouldDisableSubmitButton(formProps.isValid, formProps.dirty)}>
                      Submit
                    </Button>
                  </PageContentFooter>
                </PageContentContainer>
              </Element>
            );
          }}
        </AddSessionLogFormik>
      )}

      {showAddSessionDialog && (
        <AddSessionDialog
          skipSelectProvider={!!provider}
          onCreate={handleOnCreateSessionLog}
          onClose={closeAddStudentDialog}
        />
      )}
      <CancelDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        onCancelAll={handleCancelAll}
        onCancelRemaining={handleCancelRemaining}
      />

      <ConfirmCancelCompletedDialog />
      <ConfirmCancelOneStudentDialog />
      <ConfirmCancelAllDialog />
      <ConfirmCancelRemainingDialog />
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const mapStateToProps = (state: IRootState) => {
  const { provider, providerHUserId, students, completedStudentIds, progress, submissions, supportTypeId, header } =
    state.sessionLogs.addSession;

  const { absentReason, contact, sessionTypes, deliveryMode, outcomes, practices, referredBy, targets, progressMeasures, behaviorMeasures, searchLocations } =
    state.sessionLogs.addSession.search;

  const { searchStudents } = state.sessionLogs.searchLogs;
  const templateForm = state.sessionLogs.addSession.template;
  return {
    permissions: selectUserPermissions(state),
    provider,
    providerHUserId: providerHUserId.data[0]?.provider_h_user_id || 723934,
    supportTypeId: supportTypeId.data[0]?.support_type_id || null,
    students,
    templateForm,
    completedStudentIds,
    formProgress: progress,
    submissions,
    hStudentId: searchStudents.data[0]?.hStudentId,
    loading:
      // absentReason.loading ||
      // contact.loading ||
      // deliveryMode.loading ||
      // outcomes.loading ||
      // practices.loading ||
      // referredBy.loading ||
      // targets.loading ||
      submissions.loading || supportTypeId.loading || header.loading || searchLocations.loading,
    // providerHUserId.loading,
    contact,
    sessionTypes,
    deliveryMode,
    outcomes,
    practices,
    targets,
    progressMeasures,
    behaviorMeasures,
    absentReason,
    referredBy,
    studentInfo: header.data,
    studentInfoLoading: header.loading,
    location: searchLocations.data,
  };
};

const mapDispatchToProps = {
  cancelAddingSession: cancelAddingSession,
  cancelRemainingStudents: cancelRemainingStudentsAddingSession,
  saveFormProgress: saveAddLogFormProgress,
  createSessionLogs: createSessionLogsAsync.request,
  setSelectedProviderAddSession: setSelectedProviderAddSession,
  setSessionDateAddSession: setSessionDateAddSession,
  setAddSessionTemplateFirstInfo: setAddSessionTemplateFirstInfo,
  searchStudents: searchStudentsAsync.request,

  getTargetOptions: getTargetOptionsSLAsync.request,
  getBehaviorCategoryOptions: getBehaviorCategoryOptionsSLAsync.request,
  getBehaviorMeasureOptions: getBehaviorMeasureOptionsSLAsync.request,
  getPracticeOptions: getPracticeElementOptionsSLAsync.request,
  getOutcomeOptions: getOutcomeOptionsSLAsync.request,
  getContactOptions: getContactTypesOptionsSLAsync.request,
  getSessionOptions: getSessionTypesOptionsSLAsync.request,
  getDeliveryModeOptions: getDeliveryTypesOptionsSLAsync.request,
  getReferredByOptions: getReferredByOptionsSLAsync.request,
  getAbsentReasonOptions: getAbsentReasonOptionsSLAsync.request,

  getSupportTypeId: getSupportTypeIdForRelatedServiceAsync.request,
  clearSupportTypeId: clearSupportTypeId,
  clearHStudentId: clearHStudentId,

  getSLHeader: getSLHeaderAsync.request,
  clearSLHeader: getSLHeaderAsync.cancel,
  clearCompletedStudentSL: clearCompletedStudentSL,

  searchSLLocation: searchLocationAddSLAsync.request,
  clearSLLocation: searchLocationAddSLAsync.cancel,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSessionLog);