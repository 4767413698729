import { ProviderNameEditClone, ServiceLocation } from 'src/redux/schedulerRedux/types';
import {
  AddSessionLogType,
  UpdateSessionLogPayload,
  EditableStudentsType,
  SessionLogDetail,
} from 'src/redux/sessionLogsRedux/types';
import { toApiDateOrNull, toApiTimeOrNull } from 'src/utils/momentUtils';
import { isEmpty } from 'src/validations';

export const formInitialEdit: AddSessionLogType = {
  student: null,
  hStudentId: null,
  studentNotAvailable: false,
  reasonStudentNotAvailable: null,
  provider: null,
  providerId: null,
  providerHUserId: null,
  credentialName: '',
  primaryMethodOfContact: null,
  sessionType: null,
  referredBy: null,
  referredOtherDescription: '',
  sessionDate: null,
  startTime: null,
  endTime: null,
  sessionLength: null,
  groupSize: null,
  location: null,
  locationDetails: null,
  deliveryMode: null,
  roomName: '',
  targets: null,
  outcomes: null,
  progressMeasures: null,
  behaviorMeasures: null,
  practiceElements: null,
  notes: [''],
  licenseAttestUserId: null,
  licenseAttestName: '',
  licenseAttestDate: null,
  comments: null
};

export const convertLogDetailsToFormValue = (logDetail: SessionLogDetail): AddSessionLogType => {
  const convertTPO = {
    targets: [],
    practiceElements: [],
    outcomes: [],
    notes: [],
  };
  logDetail &&
    logDetail.TPO.forEach((element, index) => {
      const target = {
        tpoId: element.id,
        id: element.targetId,
        needDescription: element.targetOther ? true : false,
        description: isEmpty(element.targetOther) ? null : element.targetOther,
        targetFullName: element.targetFullName,
      };
      const practice = {
        id: element.practiceId,
        needDescription: element.practiceOther ? true : false,
        description: isEmpty(element.practiceOther) ? null : element.practiceOther,
        practiceFullName: isEmpty(element.practiceFullName) ? null: element.practiceFullName,
      }
      convertTPO.targets.push(target);
      convertTPO.practiceElements.push(practice);
      convertTPO.outcomes.push(element.outcomeId);
      convertTPO.notes.push( (isEmpty(element.notes) ? '' : element.notes) );
    });

    const convertBIMAS = {
      behaviorMeasure: [],
    };
    logDetail &&
      logDetail.BIMAS.forEach((element, index) => {
        const behaviorMeasure = {
          id: element.id,
          measureCategoryCode: element.measureCategoryCode,
          measureId: isEmpty(element.measureId) ? null : element.measureId,
          measureOther: isEmpty(element.measureOther) ? '' : element.measureOther,
          score: isEmpty(element.score) ? '' : element.score,
        };
        convertBIMAS.behaviorMeasure.push(behaviorMeasure);
      });

  isEmpty(convertTPO.outcomes) && convertTPO.outcomes.push(null);
  isEmpty(convertTPO.notes) && convertTPO.notes.push('');
  const student: EditableStudentsType = {
    studentId: logDetail.studentId,
    studentExtId: logDetail.studentExtId,
    firstName: logDetail.studentFirstName,
    lastName: logDetail.studentLastName,
    schoolName: logDetail.studentSchoolName,
    schoolCode: logDetail.schoolCode,
    grade: logDetail.grade,
    birthDate: logDetail.birthDate,
    gender: logDetail.gender,
    supportId: logDetail.supportId,
    supportType: logDetail.supportType,
    esy: logDetail.serviceEsy,
    frequency: logDetail.frequency,
    startDate: logDetail.serviceStartDate,
    endDate: logDetail.serviceEndDate,
    providerId: logDetail.providerId,
  };
  const provider: ProviderNameEditClone = {
    providerId: logDetail.providerId,
    userId: logDetail.providerUserId,
    hUserId: logDetail.providerHUserId,
    username: logDetail.providerFullName,
    firstName: logDetail.providerFullName.split(' ')[0],
    lastName: logDetail.providerFullName.split(' ')[1],
    position: logDetail.providerPositionName,
    credentialName: logDetail.providerCredential,
    credentialType: logDetail.providerCredentialType,
  };
  const location: ServiceLocation = {
    locationId: logDetail.locationId, //TODO: need to update to schoolId
    locationName: logDetail.locationName,
    locationCode: logDetail.sessionLocationCode,
  };
  return {
    sessionId: logDetail.sessionId,
    student: student,
    hStudentId: logDetail.hStudentId,
    supportTypeId: logDetail.supportTypeId,
    studentNotAvailable: logDetail.studentAbsentFlag,
    reasonStudentNotAvailable: logDetail.studentAbsentReason ? `${logDetail.studentAbsentReason}` : '',
    provider: provider,
    providerId: student ? student.providerId : logDetail.providerId,
    providerHUserId: logDetail.providerHUserId,
    credentialName: logDetail.providerCredential,
    primaryMethodOfContact: logDetail.contactType,
    sessionType: logDetail.sessionType,
    referredBy: logDetail.referredBy,
    referredOtherDescription: logDetail.referredByOther,
    sessionDate: logDetail.sessionDate,
    startTime: logDetail.sessionStart,
    endTime: logDetail.sessionEnd,
    sessionLength: logDetail.sessionMinutes === null ? `` : `${logDetail.sessionMinutes}`,
    groupSize: logDetail.groupSize === null ? `` : `${logDetail.groupSize}`,
    location: location,
    locationDetails: logDetail.locationDetails,
    deliveryMode: logDetail.deliveryMode,
    roomName: logDetail.roomName ? `${logDetail.roomName}` : '',
    targets: convertTPO.targets,
    outcomes: convertTPO.outcomes,
    practiceElements: convertTPO.practiceElements,
    progressMeasures: convertBIMAS.behaviorMeasure,
    behaviorMeasures: convertBIMAS.behaviorMeasure,
    licenseAttestUserId: logDetail.licenseAttestUserId ? logDetail.licenseAttestUserId : null,
    licenseAttestName: logDetail.licenseAttestName ? logDetail.licenseAttestName : null,
    licenseAttestDate: logDetail.licenseAttestDate ? `${logDetail.licenseAttestDate}` : '',
    // @ts-ignore
    notes: convertTPO.notes,
    comments: logDetail.comments,
  };
};

export const formValuesEditToPayload = ({
  student,
  sessionId,
  hStudentId,
  studentNotAvailable,
  reasonStudentNotAvailable,
  provider,
  providerId,
  providerHUserId,
  primaryMethodOfContact,
  sessionType,
  referredBy,
  referredOtherDescription,
  sessionDate,
  startTime,
  sessionLength,
  groupSize,
  location,
  locationDetails,
  deliveryMode,
  roomName,
  targets,
  outcomes,
  practiceElements,
  behaviorMeasures,
  progressMeasures,
  notes,
  comments,
  licenseAttestUserId,
  licenseAttestName,
  licenseAttestDate,
  supportTypeId,
}: AddSessionLogType): UpdateSessionLogPayload => {
  return {
    sessionId: sessionId,
    studentId: student.studentId,
    hStudentId: hStudentId,
    providerUserId: provider.userId,
    providerHUserId: providerHUserId,
    providerId: providerId,
    supportType: student.supportType,
    supportTypeId: supportTypeId ? supportTypeId : null,
    supportId: student.supportId,
    sessionDate: toApiDateOrNull(sessionDate),
    sessionTime: studentNotAvailable ? '' : toApiTimeOrNull(startTime),
    sessionMinutes: studentNotAvailable ? null : +sessionLength,
    studentAbsentFlag: studentNotAvailable ? 1 : 0,
    studentAbsentReason: studentNotAvailable ? reasonStudentNotAvailable : '',
    contactType: studentNotAvailable ? '' : primaryMethodOfContact,
    // TODO: UPDATE sessionType logic
    sessionType: studentNotAvailable ? '' : sessionType,
    referredBy: studentNotAvailable ? '' : referredBy,
    referredByOther: studentNotAvailable ? '' : referredOtherDescription,
    groupSize: studentNotAvailable ? null : isEmpty(groupSize) ? null : +groupSize,
    locationId: studentNotAvailable ? null : location.locationId,
    locationDetails: studentNotAvailable ? '' : locationDetails,
    deliveryMode: studentNotAvailable ? '' : deliveryMode,
    roomName: studentNotAvailable ? null : roomName ? roomName : null,
    comments: comments,
    medicaidFlag: 1,
    licenseAttestUserId: licenseAttestUserId,
    licenseAttestName: licenseAttestName,
    licenseAttestDate: licenseAttestDate,
    medicaidMinutes: studentNotAvailable ? null : +sessionLength,
    logs: [
      {
        comment: 'Edit Log',
      },
    ],
    TPO: studentNotAvailable
      ? [
          {
            id: null,
            targetId: null,
            targetFullName: '',
            targetOther: '',
            practiceId: null,
            practiceFullName: '',
            practiceOther: '',
            outcomeId: null,
            notes: '',
          },
        ]
      : // ?  undefined
        Array.from({ length: targets.length }, (_, i) => ({
          id: targets[i] !== null ? +targets[i].tpoId : null,
          targetId: targets[i] !== null ? +targets[i].id : null,
          targetOther: targets[i] !== null ? targets[i].description : null,
          targetFullName: targets[i] !== null ? targets[i].targetFullName : null,
          practiceId: practiceElements[i] !== null ? +practiceElements[i].id : null,
          practiceOther: practiceElements[i] !== null ? practiceElements[i].description : null,
          practiceFullName: practiceElements[i] !== null ? practiceElements[i].practiceFullName : null,
          outcomeId: outcomes[i] !== null ? outcomes[i] : null,
          notes: notes[i] !== null ? notes[i] : null,
        })),
        BIMAS: studentNotAvailable
        ? [
            {
              id: null,
              measureCategoryCode: '',
              measureId: null,
              measureOther: '',
              score: '',
            },
          ]
        : // ?  undefined
          Array.from({ length: progressMeasures.length }, (_, i) => ({
            id: progressMeasures[i] !== null ? +progressMeasures[i].id : null,
            measureCategoryCode: progressMeasures[i] !== null ? progressMeasures[i].measureCategoryCode : null,
            measureId: progressMeasures[i] !== null && progressMeasures[i].measureCategoryCode === 'bimas' ? progressMeasures[i].measureId : null,
            measureOther: progressMeasures[i] !== null && progressMeasures[i].measureCategoryCode === 'other' ? progressMeasures[i].measureOther : null,
            score: progressMeasures[i] !== null ? progressMeasures[i].score : null,
          })),
  };
};
