import { SearchName } from 'src/containers/SessionLogsContainers/AddProviderAndStudentsDialog/AddStudentsDialog';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { ProviderName, SchoolName, ServiceLocation, StudentName } from '../schedulerRedux/types';
import { Pagination } from '../types';
import {
  AddSessionLogFormProgress,
  AddSessionTemplateType,
  BehaviorCategoryType,
  BehaviorMeasureType,
  CreateSessionLogPayload,
  EditableStudentsType,
  GetEditableStudentsRequest,
  getProviderHUserIdType,
  getSLHeaderParams,
  getSupportTypeIdParams,
  OptionSelectSLType,
  sessionOptionsSelectSLType,
  OutComeType,
  PracticeElementType,
  ProviderHUserId,
  RecentLogsType,
  SearchSessionRequestType,
  SearchSessionType,
  SessionLogDetail,
  SLHeader,
  SupportTypeId,
  TargetAreaType,
} from './types';
const prefix = 'sessionLogs';
export const setSchedulerProvidersPagination = createAction('scheduler/providers/SET_PAGINATION')<Pagination>();

//#region Search actions
const createSearchAsync = <Return extends unknown>(funcName: string = '', type: string) =>
  createAsyncAction(
    `${prefix}/${funcName}/SEARCH_${type}_REQUEST`,
    `${prefix}/${funcName}/SEARCH_${type}_SUCCESS`,
    `${prefix}/${funcName}/SEARCH_${type}_FAILURE`,
    `${prefix}/${funcName}/SEARCH_${type}_CANCEL`,
  )<string, Return, Error, void>();

export const searchProvidersAsync = createSearchAsync<ProviderName[]>('search', 'PROVIDERS');
export const searchStudentsAsync = createSearchAsync<StudentName[]>('search', 'STUDENTS');
export const searchSchoolsAsync = createSearchAsync<SchoolName[]>('search', 'SCHOOLS');

export const setSelectedProviderFilter = createAction(`${prefix}/SET_SELECTED_PROVIDER_FILTER`)<ProviderName>();

export const setSelectedSchoolFilter = createAction(`${prefix}/SET_SELECTED_SCHOOL_FILTER`)<SchoolName>();
export const setSelectedStudentFilter = createAction(`${prefix}/SET_SELECTED_STUDENT_FILTER`)<StudentName>();

//#end-region search actions

// Recent Log
export const getRecentLogsAsync = createAsyncAction(
  `${prefix}/GET_RECENT_LOGS_REQUEST`,
  `${prefix}/GET_RECENT_LOGS_SUCCESS`,
  `${prefix}/GET_RECENT_LOGS_FAILURE`,
)<void, RecentLogsType[], Error>();

export const setModeSelectedLog = createAction(`${prefix}/SET_MODE_SELECTED_LOG`)<string>();
// End Recent Log

// Add Session

export const setLicenseAttestFlagSL = createAction(`${prefix}/SET_LICENSE_ATTEST_FLAG`)<boolean>();

export const getStudentsSessionAsync = createAsyncAction(
  `${prefix}/add/SEARCH_SESSION_REQUEST`,
  `${prefix}/add/SEARCH_SESSION_SUCCESS`,
  `${prefix}/add/SEARCH_SESSION_FAILURE`,
)<SearchName, EditableStudentsType[], Error>();

export const searchActiveProvidersAsync = createSearchAsync<ProviderName[]>('add', 'ACTIVE_PROVIDERS');
export const searchSchoolsAddSLAsync = createSearchAsync<SchoolName[]>('add', 'SCHOOLS_FILTER');
export const searchLocationAddSLAsync = createSearchAsync<ServiceLocation[]>('add', 'LOCATION');
export const setSelectedProviderAddSession = createAction(`${prefix}/add/SET_SELECTED_PROVIDER`)<ProviderName>();

export const getEditableStudentsAsync = createAsyncAction(
  `${prefix}/add/GET_EDITABLE_STUDENTS_REQUEST`,
  `${prefix}/add/GET_EDITABLE_STUDENTS_SUCCESS`,
  `${prefix}/add/GET_EDITABLE_STUDENTS_FAILURE`,
)<GetEditableStudentsRequest, SearchSessionType, Error>();

export const setFiltersEditableStudent = createAction(
  `${prefix}/add/SET_FILTERS_EDITABLE_STUDENTS`,
)<GetEditableStudentsRequest>();

export const setSelectedStudentsAddSession = createAction(`${prefix}/add/SET_SELECTED_STUDENTS`)<
  EditableStudentsType[]
>();
export const clearSearchEditableStudents = createAction(`${prefix}/add/CLEAR_EDITABLE_STUDENTS`)();
export const clearCompletedStudentSL = createAction(`${prefix}/add/CLEAR_COMPLETED_STUDENT`)<{ studentId: number }>();

export const setSessionDateAddSession = createAction(`${prefix}/add/SET_SESSION_DATE`)<string>();

export const setAddSessionTemplate = createAction(`${prefix}/add/SET_ADD_SESSION_TEMPLATE`)<AddSessionTemplateType>();
export const setAddSessionTemplateFirstInfo = createAction(`${prefix}/add/SET_ADD_SESSION_TEMPLATE_FIRST_INFO`)<{
  provider: ProviderName;
  credentialName: string;
  sessionDate: string;
}>();

export const saveAddLogFormProgress = createAction(`${prefix}/add/SAVE_ADD_LOG_PROGRESS`)<AddSessionLogFormProgress>();

export const cancelAddingSession = createAction(`${prefix}/add/CANCEL_ADDING_SESSION`)();
export const clearSelectedLog = createAction(`${prefix}/search/CLEAR_SELECTED_LOG`)();
export const cancelRemainingStudentsAddingSession = createAction(`${prefix}/add/CANCEL_REMAINING_STUDENTS`)<
  (string | number)[]
>();

export const getTargetOptionsSLAsync = createAsyncAction(
  `${prefix}/add/GET_TARGET_AREAS_REQUEST`,
  `${prefix}/add/GET_TARGET_AREAS_SUCCESS`,
  `${prefix}/add/GET_TARGET_AREAS_FAILURE`,
)<void, TargetAreaType[], Error>();

export const getBehaviorCategoryOptionsSLAsync = createAsyncAction(
  `${prefix}/add/GET_BEHAVIOR_CATEGORIES_REQUEST`,
  `${prefix}/add/GET_BEHAVIOR_CATEGORIES_SUCCESS`,
  `${prefix}/add/GET_BEHAVIOR_CATEGORIES_FAILURE`,
)<void, BehaviorCategoryType[], Error>();

export const getBehaviorMeasureOptionsSLAsync = createAsyncAction(
  `${prefix}/add/GET_BEHAVIOR_MEASURE_OPTIONS_REQUEST`,
  `${prefix}/add/GET_BEHAVIOR_MEASURE_OPTIONS_SUCCESS`,
  `${prefix}/add/GET_BEHAVIOR_MEASURE_OPTIONS_FAILURE`,
)<void, BehaviorMeasureType[], Error>();

export const getPracticeElementOptionsSLAsync = createAsyncAction(
  `${prefix}/add/GET_PRACTICE_ELEMENTS_REQUEST`,
  `${prefix}/add/GET_PRACTICE_ELEMENTS_SUCCESS`,
  `${prefix}/add/GET_PRACTICE_ELEMENTS_FAILURE`,
)<void, PracticeElementType[], Error>();

export const getOutcomeOptionsSLAsync = createAsyncAction(
  `${prefix}/add/GET_OUTCOME_REQUEST`,
  `${prefix}/add/GET_OUTCOME_SUCCESS`,
  `${prefix}/add/GET_OUTCOME_FAILURE`,
)<void, OutComeType[], Error>();

export const getDeliveryTypesOptionsSLAsync = createAsyncAction(
  `${prefix}/add/GET_DELIVERY_TYPES_REQUEST`,
  `${prefix}/add/GET_DELIVERY_TYPES_SUCCESS`,
  `${prefix}/add/GET_DELIVERY_TYPES_FAILURE`,
)<void, OptionSelectSLType[], Error>();

export const getContactTypesOptionsSLAsync = createAsyncAction(
  `${prefix}/add/GET_CONTACT_TYPES_REQUEST`,
  `${prefix}/add/GET_CONTACT_TYPES_SUCCESS`,
  `${prefix}/add/GET_CONTACT_TYPES_FAILURE`,
)<void, OptionSelectSLType[], Error>();

export const getSessionTypesOptionsSLAsync = createAsyncAction(
  `${prefix}/add/GET_SESSION_TYPES_REQUEST`,
  `${prefix}/add/GET_SESSION_TYPES_SUCCESS`,
  `${prefix}/add/GET_SESSION_TYPES_FAILURE`,
)<void, sessionOptionsSelectSLType[], Error>();

export const getReferredByOptionsSLAsync = createAsyncAction(
  `${prefix}/add/GET_REFERRED_BY_REQUEST`,
  `${prefix}/add/GET_REFERRED_BY_SUCCESS`,
  `${prefix}/add/GET_REFERRED_BY_FAILURE`,
)<void, OptionSelectSLType[], Error>();

export const getAbsentReasonOptionsSLAsync = createAsyncAction(
  `${prefix}/add/GET_ABSENT_REASON_REQUEST`,
  `${prefix}/add/GET_ABSENT_REASON_SUCCESS`,
  `${prefix}/add/GET_ABSENT_REASON_FAILURE`,
)<void, OptionSelectSLType[], Error>();

export const getProviderHUserSLAsync = createAsyncAction(
  `${prefix}/add/GET_PROVIDER_H_USER_ID_REQUEST`,
  `${prefix}/add/GET_PROVIDER_H_USER_ID_SUCCESS`,
  `${prefix}/add/GET_PROVIDER_H_USER_ID_FAILURE`,
)<getProviderHUserIdType, ProviderHUserId[], Error>();

export const createSessionLogsAsync = createAsyncAction(
  `${prefix}/add/CREATE_SESSION_LOG_REQUEST`,
  `${prefix}/add/CREATE_SESSION_LOG_SUCCESS`,
  `${prefix}/add/CREATE_SESSION_LOG_FAILURE`,
)<
  {
    payloads: CreateSessionLogPayload[];
    final: boolean;
  },
  {
    records: SessionLogDetail[];
    final: boolean;
  },
  Error
>();

export const updateSessionLogsAsync = createAsyncAction(
  `${prefix}/add/UPDATE_SESSION_LOG_REQUEST`,
  `${prefix}/add/UPDATE_SESSION_LOG_SUCCESS`,
  `${prefix}/add/UPDATE_SESSION_LOG_FAILURE`,
)<
  {
    sessionId: number;
    payload: CreateSessionLogPayload;
  },
  {
    records: SessionLogDetail[];
    final: boolean;
  },
  Error
>();

export const removeSessionLogByIdAsync = createAsyncAction(
  `${prefix}/add/REMOVE_LOG_BY_ID_REQUEST`,
  `${prefix}/add/REMOVE_LOG_BY_ID_SUCCESS`,
  `${prefix}/add/REMOVE_LOG_BY_ID_FAILURE`,
)<{ sessionId: number }, { sessionId: number }, Error>();

export const getSupportTypeIdForRelatedServiceAsync = createAsyncAction(
  `${prefix}/add/GET_SUPPORT_TYPE_ID_REQUEST`,
  `${prefix}/add/GET_SUPPORT_TYPE_ID_SUCCESS`,
  `${prefix}/add/GET_SUPPORT_TYPE_ID_FAILURE`,
)<getSupportTypeIdParams, SupportTypeId, Error>();

export const clearSupportTypeId = createAction(`${prefix}/add/CLEAR_SUPPORT_TYPE_ID`)();
export const clearHStudentId = createAction(`${prefix}/add/CLEAR_H_STUDENT_ID`)();

export const getSLHeaderAsync = createAsyncAction(
  `${prefix}/add/GET_SL_HEADER_REQUEST`,
  `${prefix}/add/GET_SL_HEADER_SUCCESS`,
  `${prefix}/add/GET_SL_HEADER_FAILURE`,
  `${prefix}/add/GET_SL_HEADER_CANCEL`,
)<getSLHeaderParams, SLHeader, Error, void>();

//#end add session

// Search Logs
export const searchLogsAsync = createAsyncAction(
  `${prefix}/search/SEARCH_LOGS_REQUEST`,
  `${prefix}/search/SEARCH_LOGS_SUCCESS`,
  `${prefix}/search/SEARCH_LOGS_FAILURE`,
)<SearchSessionRequestType, SearchSessionType, Error>();

export const getLogByIdAsync = createAsyncAction(
  `${prefix}/search/GET_LOG_BY_ID_REQUEST`,
  `${prefix}/search/GET_LOG_BY_ID_SUCCESS`,
  `${prefix}/search/GET_LOG_BY_ID_FAILURE`,
)<number, SessionLogDetail, Error>();

export const getLogByIdCloneAsync = createAsyncAction(
  `${prefix}/search/GET_LOG_BY_ID_CLONE_REQUEST`,
  `${prefix}/search/GET_LOG_BY_ID_CLONE_SUCCESS`,
  `${prefix}/search/GET_LOG_BY_ID_CLONE_FAILURE`,
)<number, SessionLogDetail, Error>();

export const clearSearchSessionLogs = createAction(`${prefix}/search/CLEAR_SEARCH_LOGS`)();
