import React from 'react';
import './styles.scss';

import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';

import { Grid, IconButton, Paper, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MdMoreVert } from 'react-icons/md';
import ActionsButtonPopover, { ActionsButton } from '../ActionsButtonPopover';
import moment from 'moment';
import { SessionLogDetail } from 'src/redux/sessionLogsRedux/types';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { isEmpty } from 'src/validations';
import { getSupportTypeName } from 'src/utils/nameUtils';

const clsPrefix = 'session-log-item';

const noop = e => {};

const SessionLogItem: React.VFC<Props> = ({ log, onEdit = noop, onCopy = noop, onDelete = noop, onClick = noop }) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [actionPopoverAnchor, setActionPopoverAnchor] = React.useState<HTMLButtonElement>(null);
  const toolTipPreferences = localStorage.getItem('toolTipPreferences') === 'true' ? true : false;

  const handleOnClick = callback => e => {
    e.stopPropagation();
    callback(log);
  };

  const actions = (renderAsMenu: boolean, asGridItem: boolean) => (
    <ActionsButton
      renderAsMenu={renderAsMenu}
      asGridItem={asGridItem}
      onEdit={handleOnClick(onEdit)}
      onCopy={handleOnClick(onCopy)}
      onDelete={handleOnClick(onDelete)}
    />
  );
  
  return (
    <Paper
      variant="outlined"
      elevation={3}
      className={`${clsPrefix}`}
      key={log.sessionId}
      onClick={handleOnClick(onClick)}>
      {!isMobile && (
        <Grid container spacing={isMobile ? 1 : 2}>
          <Grid
            item
            xs={12}
            sm={2}
            className={`${clsPrefix}-time ${log.studentAbsentFlag ? `${clsPrefix}-time-absent` : ''}`}>
            <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Session Month, Year" : ''}>
              <p>{log.sessionDate ? moment(log.sessionDate).format('MMM YYYY') : 'N/A'}</p>
            </Tooltip>
            <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Session Date" : ''}>
              <h3>{log.sessionDate ? moment(log.sessionDate).format('DD') : 'N/A'}</h3>
            </Tooltip>
            <p>{!log.sessionTime && !log.sessionMinutes && 'ABSENT'}</p>
            <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Session Time" : ''}>
              <p>{log.sessionTime ? moment(log.sessionTime, 'HH:mm:ss').format('hh:mm A') : ''}</p>
            </Tooltip>
            <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Session Minutes" : ''}>
              <p>{log.sessionMinutes ? `(${log.sessionMinutes} mins)` : ''}</p>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4} className={`${clsPrefix}__border-bottom`}>
            <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Student Name (Student ID)" : ''}>
              <h4 className="mb-8">
                {log.studentFirstName} {log.studentLastName} ({log.studentExtId})
              </h4>
            </Tooltip>
            <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Student School Name (Grade)" : ''}>
              <h5>
                {log.studentSchoolName} ({log.grade})
              </h5>
            </Tooltip>
            {log.locationName && (
              <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? `Location Name ${log.locationDetails ? `- Details` : ``}` : ''}>
                <h5 style={{ overflowWrap: 'break-word' }}>
                  {log.locationName} {log.locationDetails ? `- ${log.locationDetails}` : ''}
                </h5>
              </Tooltip>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={log.canEdit ? 4 : 5}
            container
            direction="column"
            justifyContent={log.supportType === 'unsched' ? 'center' : 'flex-start'}>
            <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Support Type" : ''}>
              <h5 className={`mb-8`}>
                {!isEmpty(log.supportTypeName) ? log.supportTypeName : getSupportTypeName(log.supportType)}
              </h5>
            </Tooltip>
            {!isEmpty(log.serviceStartDate) && !isEmpty(log.serviceEndDate) && (
              <Tooltip arrow placement="bottom-start" title={ toolTipPreferences ? "Service Start Date - End Date" : ''}>
                <h5 className="mb-8">
                  {moment(log.serviceStartDate).format('MM/DD/YYYY')}
                  {' - '}
                  {moment(log.serviceEndDate).format('MM/DD/YYYY')}
                  {log.serviceEsy ? ' (ESY)' : ''}
                </h5>
              </Tooltip>
            )}
            <Tooltip
              arrow
              placement="bottom-start"
              title={ toolTipPreferences ? `Provider Name ${log.providerCredentialName ? ` / Credentials` : ''}` : ''}>
              <h5>
                {log.providerFullName} {log.providerCredentialName ? ` / ${log.providerCredentialName}` : ''}
              </h5>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={2} spacing={2} container justifyContent="flex-end" alignItems="flex-start">
            {log.canEdit && actions(false, true)}
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <>
          <Grid container spacing={isMobile ? 1 : 2}>
            <Grid
              item
              xs={4}
              sm={2}
              className={`${clsPrefix}-time ${log.studentAbsentFlag ? `${clsPrefix}-time-absent` : ''}`}>
              <p>{log.sessionDate ? moment(log.sessionDate).format('MMM YYYY') : 'N/A'}</p>
              <h2>{log.sessionDate ? moment(log.sessionDate).format('DD') : 'N/A'}</h2>
              <p>
                {log.studentAbsentFlag ? (
                  'ABSENT'
                ) : (
                  <>
                    {log.sessionTime ? moment(log.sessionTime, 'HH:mm:ss').format('hh:mm A') : ''}
                    <br />
                    {log.sessionMinutes ? `(${log.sessionMinutes} mins)` : ''}
                  </>
                )}
              </p>
            </Grid>
            <Grid item container xs={8} spacing={1}>
              <Grid item xs={12}>
                {isMobile ? (
                  <h5 className="">
                    <b>
                      {log.studentFirstName} {log.studentLastName} ({log.studentExtId})
                    </b>
                  </h5>
                ) : (
                  <h4 className="mb-8">
                    {log.studentFirstName} {log.studentLastName} ({log.studentExtId})
                  </h4>
                )}
                <h5>
                  {log.studentSchoolName} ({log.grade})
                </h5>
                {!isMobile && (
                  <h5 style={{ overflowWrap: 'break-word' }}>
                    {log.locationName} {log.locationDetails ? `- ${log.locationDetails}` : ''}
                  </h5>
                )}
              </Grid>
              <Grid item xs={12}>
                <h5 className={isMobile ? '' : 'mb-8'}>
                  {!isEmpty(log.supportTypeName) ? log.supportTypeName : getSupportTypeName(log.supportType)}
                </h5>
                {!isEmpty(log.serviceStartDate) && !isEmpty(log.serviceEndDate) && (
                  <h5 className="mb-8">
                    {moment(log.serviceStartDate).format('MM/DD/YYYY')}
                    {' - '}
                    {moment(log.serviceEndDate).format('MM/DD/YYYY')}
                    {log.serviceEsy ? ' (ESY)' : ''}
                  </h5>
                )}
                {isMobile && (
                  <h5>
                    {log.locationName} {log.locationDetails ? `- ${log.locationDetails}` : ''}
                  </h5>
                )}
                <h5>
                  {log.providerFullName} {log.providerCredentialName ? ` / ${log.providerCredentialName}` : ''}
                </h5>
              </Grid>
            </Grid>
          </Grid>
          {log.canEdit && (
            <div className={`${clsPrefix}__moreBtn`}>
              <IconButton size="small" onClick={({ currentTarget }) => setActionPopoverAnchor(currentTarget)}>
                <MdMoreVert />
              </IconButton>
              <ActionsButtonPopover container={actionPopoverAnchor} onClose={() => setActionPopoverAnchor(null)}>
                {actions(true, false)}
              </ActionsButtonPopover>
            </div>
          )}
        </>
      )}
    </Paper>
    // </Tooltip>
  );
};

type SessionLogItemCallback<T = SessionLogDetail> = (log: T) => void;
type SessionLogItemProps<T = SessionLogDetail> = {
  log?: SessionLogDetail;
  onEdit?: SessionLogItemCallback<T>;
  onCopy?: SessionLogItemCallback<T>;
  onDelete?: SessionLogItemCallback<T>;
  onClick?: SessionLogItemCallback<T>;
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & SessionLogItemProps;

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SessionLogItem);
